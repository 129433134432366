import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.$ = $;
window.jQuery = $;
// require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';


$(document).foundation();


function isMobile() {
  return mobileSniff();// /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(window.navigator.userAgent);
}
function iPhoneSniff() {
  return /iP(ad|hone|od).*OS/.test(window.navigator.userAgent);
}
function androidSniff() {
  return /Android/.test(window.navigator.userAgent);
}
function mobileSniff() {
  return iPhoneSniff() || androidSniff();
}


// SVG to PNG fallback
if(!Modernizr.svg) {
    $('img[src*="svg"]').attr('src', function() {
        return $(this).attr('src').replace('.svg', '.png');
    });
} else {
  /*  Replace all SVG images with inline SVG */
  $('img.svg').each(function(){
    var $img = $(this);
    var imgID = $img.attr('id');
    var imgClass = $img.attr('class');
    var imgURL = $img.attr('src');

    $.get(imgURL, function(data) {
       //Get the SVG tag, ignore the rest
      var $svg = $(data).find('svg');
       //Add replaced image\'s ID to the new SVG
      if (typeof imgID !== 'undefined') {
        $svg = $svg.attr('id', imgID);
      }
       //Add replaced image\'s classes to the new SVG
      if (typeof imgClass !== 'undefined') {
        $svg = $svg.attr('class', imgClass+' replaced-svg');
      }
       //Remove any invalid XML tags as per http:validator.w3.org
      $svg = $svg.removeAttr('xmlns:a');
       //Replace image with new SVG
      $img.replaceWith($svg);
    });
  });
}

navigator.sayswho = (function(){
  var ua= navigator.userAgent, tem, 
  M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if(/trident/i.test(M[1])){
      tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE '+(tem[1] || '');
  }
  if(M[1]=== 'Chrome'){
      tem= ua.match(/\bOPR\/(\d+)/)
      if(tem!= null) return 'Opera '+tem[1];
  }
  M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
  if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
  return M.join(' ');
})();

var _width = $(window).width();
$('body').attr('data-resolution', _width);
$('body').attr('data-touch', Modernizr.touchevents);
var browser = navigator.sayswho;
$('body').addClass(browser.split(' ')[0].toLowerCase());


import {gsap, Power1, Power2, Power4, Back} from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";﻿﻿﻿﻿﻿
// import MorphSVGPlugin from "gsap/MorphSVGPlugin";﻿﻿﻿﻿﻿
// import DrawSVGPlugin from "gsap/DrawSVGPlugin";﻿﻿﻿﻿﻿
import SplitText from "gsap/SplitText";﻿﻿﻿﻿﻿
// import CSSRulePlugin from "gsap/CSSRulePlugin";﻿﻿﻿﻿﻿
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, SplitText);

$('[data-scroll]').on('click', function(e){
  e.preventDefault();
  let link = $(this).attr('href');
  let target = '#' + link.split('#')[1];
  gsap.to(window, .8, {scrollTo: $(link).offset().top - 75, ease:Power1.easeInOut});
  return false;
});


let home = document.querySelector(".HomePage"), heroSplitText, mapSplitText, contactSplitText, textOptions, heroP, buttons, help1, help2, help3;
window.addEventListener('load', e => {
  if (!home) $('header').addClass("scroll");
  gsap.set('#main', {alpha: 1});
  gsap.to('.loader', {duration: 0.4, css:{scale: 1.5, opacity: 0}, ease: Power1.easeOut, delay: 0.3});

  if (home) {
    let cloud = document.querySelector(".cloud");
    gsap.fromTo(cloud, {left: "101%"}, {duration: 90, left: "-100%", ease: "none", repeat: -1, delay: 0.1});
    help1 = document.querySelector(".help-popup-1");
    help2 = document.querySelector(".help-popup-2");
    help3 = document.querySelector(".help-popup-3");
    heroSplitText = new SplitText("#HomeHero h1", {type: "chars,words,lines"});
    mapSplitText = new SplitText(".interactive-map h2", {type: "chars,words,lines"});
    contactSplitText = new SplitText("#HomeForm h2", {type: "chars,words,lines"});
    heroP = document.querySelector("#HomeHero .subhead");
    buttons = gsap.utils.toArray('.hero-apartament-button');
    gsap.set(help1, {alpha: 0});
    gsap.set(help2, {alpha: 0});
    gsap.set(help3, {alpha: 0});
    gsap.set("#HomeHero h1", {alpha: 0});
    gsap.set(heroP, {alpha: 0});
    gsap.set(buttons, {alpha: 0});
  }

  $('body').css({'overflow-y':'auto'});
  gsap.to('.loading-overlay', {
    duration: 0.8,
    top: "-101%",
    ease: Power1.easeIn,
    delay: 1.1,
    onComplete: () => {
      if (home) {
        gsap.set("#HomeHero h1", {alpha: 1});
        gsap.set(heroP, {alpha: 1});
        gsap.set(buttons, {alpha: 1});
        textOptions = {
          y: "+=60",
          duration: 0.8,
          autoAlpha: 0,
          transformOrigin: "50% 50% -1000",
          ease: Back.easeOut,
          delay: 0.1,
          stagger: 0.08,
          // onComplete: function(){ heroSplitText.revert(); },
        };
        let helpTL = gsap.timeline();
        helpTL.add([
          gsap.from(heroSplitText.lines, textOptions),
          gsap.from(heroP, {duration: 0.8, alpha: 0, y: "+=40", ease: Power1.easeOut, delay: 1})
        ]);
        buttons.forEach((button, i) => {
          helpTL.add(
            gsap.from(button, {duration: 0.6, alpha: 0, delay: i*0.2, onComplete: function() {
                let target = this.targets()[0];
                target.classList.add("animated");
              }
            })
          );
        });
        /*let hlp1Span = help1.querySelector("span"),
            hlp2Span = help2.querySelector("span"),
            hlp3Span = help3.querySelector("span");
        gsap.set(hlp1Span, {height: 0});
        gsap.set(hlp2Span, {width: 0});
        gsap.set(hlp3Span, {height: 0});
        helpTL.add([
          gsap.fromTo(help1, {alpha: 0, y: "+=50"}, {duration: 0.9, y: 0, alpha: 1, ease: Power1.easeOut, delay: 1}),
          gsap.to(hlp1Span, {duration: 0.5, height: 50, ease: Power1.easeOut, delay: 1.7})
        ]);
        helpTL.addPause();
        helpTL.add([
          gsap.to(help1, {duration: 0.3, y: "+=20", autoAlpha: 0, ease: Power1.easeIn}),
          gsap.fromTo(help2, {alpha: 0, x: "-=50"}, {duration: 0.9, x: 0, alpha: 1, ease: Power1.easeOut, delay: 0.5}),
          gsap.to(hlp2Span, {duration: 0.5, width: 50, ease: Power1.easeOut, delay: 1.2})
        ]);
        helpTL.addPause();
        helpTL.add([
          gsap.to(help2, {duration: 0.3, x: "-=20", autoAlpha: 0, ease: Power1.easeIn}),
          gsap.fromTo(help3, {alpha: 0, y: "+=50"}, {duration: 0.9, y: 0, alpha: 1, ease: Power1.easeOut, delay: 0.5}),
          gsap.to(hlp3Span, {duration: 0.5, height: 50, ease: Power1.easeOut, delay: 1.2})
        ]);
        helpTL.addPause();
        helpTL.add(
          gsap.to(help3, {duration: 0.3, y: "+=20", autoAlpha: 0, ease: Power1.easeIn})
        );

        let close1 = help1.querySelector("a");
        close1.addEventListener("click", () => {
          helpTL.play();
        });
        let close2 = help2.querySelector("a");
        close2.addEventListener("click", () => {
          helpTL.play();
        });
        let close3 = help3.querySelector("a");
        close3.addEventListener("click", () => {
          helpTL.play();
        });*/
      }
    }
  });

  if (!home) return;

  let ahH3 = document.querySelector(".after-hero-wrapper h3"),
      ahBtn = document.querySelector(".after-hero-wrapper .button");
  gsap.set(ahH3, {alpha: 0});
  gsap.set(ahBtn, {alpha: 0});
  ScrollTrigger.create({
    once: true,
    trigger: document.querySelector(".after-hero-wrapper"),
    start: "top center-=25%",
    end: "bottom top",
    onEnter: () => {
      gsap.fromTo(ahH3, {x: "-=80", alpha: 0}, {duration: 0.9, x: 0, alpha: 1, ease: Power1.easeOut});
      gsap.fromTo(ahBtn, {y: "+=60", alpha: 0}, {duration: 0.6, y: 0, alpha: 1, ease: Power1.easeOut, delay: 0.5});
    },
  });

  let pin = document.querySelector(".building-pin"),
      bsBg = document.querySelector("#BuildingSelector .hero-wrapper__left"),
      bsP = document.querySelector("#BuildingSelector p");
  gsap.set(pin, {alpha: 0});
  gsap.set(bsBg, {alpha: 0});
  gsap.set(bsP, {alpha: 0});
  ScrollTrigger.create({
    once: true,
    trigger: "#BuildingSelector",
    start: "top center",
    onEnter: () => {
      gsap.fromTo(pin, {y: "-=80", alpha: 0}, {duration: 0.6, y: 0, alpha: 1, ease: Back.easeOut});
      gsap.fromTo(bsBg, {alpha: 0}, {duration: 0.5, alpha: 1, delay: 0.5});
      gsap.fromTo(bsP, {x: "-=60", alpha: 0}, {duration: 0.8, x: 0, alpha: 1, delay: 0.7, ease: Power1.easeOut});
    },
  });

  let facilities = gsap.utils.toArray(".facility-card"),
      fBtn = document.querySelector("#Facilities .button");
  gsap.set(facilities, {alpha: 0});
  gsap.set(fBtn, {alpha: 0});
  ScrollTrigger.create({
    once: true,
    trigger: "#Facilities",
    start: "top center",
    onEnter: () => {
      gsap.fromTo(facilities, {y: "+=50", alpha: 0}, {duration: 0.4, y: 0, alpha: 1, ease: Back.EaseOut, stagger: 0.2});
      gsap.fromTo(fBtn, {alpha: 0}, {duration: 0.3, alpha: 1, delay: 2});
    },
  });

  let imap = document.querySelector("svg");
  gsap.set(imap, {alpha: 0});
  gsap.set(".interactive-map h2", {alpha: 0});
  // ScrollTrigger.create({
  //   once: true,
  //   trigger: "#InteractiveMap",
  //   start: "top center",
  //   onEnter: () => {
  //     gsap.set(".interactive-map h2", {alpha: 1});
  //     gsap.from(mapSplitText.lines, textOptions);
  //   },
  // });
  gsap.set(".interactive-map h2", {alpha: 1});
  gsap.from(mapSplitText.lines, {
    y: "+=60",
    duration: 0.8,
    autoAlpha: 0,
    transformOrigin: "50% 50% -1000",
    ease: Back.easeOut,
    delay: 0.1,
    stagger: 0.08,
    scrollTrigger: {
      trigger: "#InteractiveMap",
      start: "top center",
    }
  });
  gsap.fromTo(imap, {alpha: 0, y: "+=50"}, {
    duration: 0.8, 
    y: 0, 
    alpha: 1, 
    delay: 1.2, 
    ease: Power1.easeOut,
    scrollTrigger: {
      trigger: "#InteractiveMap",
      start: "top center",
    },
  });

  // gsap.set("#HomeForm h2", {alpha: 0});
  // ScrollTrigger.create({
  //   once: true,
  //   trigger: "#HomeForm",
  //   start: "top center",
  //   onEnter: () => {
  //     gsap.set("#HomeForm h2", {alpha: 1});
  //     gsap.from(contactSplitText.lines, textOptions);
  //   },
  // });
  gsap.set("#HomeForm h2", {alpha: 1});
  gsap.from(contactSplitText.lines, {
    y: "+=60",
    duration: 0.8,
    autoAlpha: 0,
    transformOrigin: "50% 50% -1000",
    ease: Back.easeOut,
    delay: 0.1,
    stagger: 0.08,
    scrollTrigger: {
      trigger: "#HomeForm",
      start: "top center",
    }
  });

});



let sectionsToAnimate = gsap.utils.toArray(".section-to-animate");
let elementsToAnimate = [
  {
    klass: ".title-to-animate", 
    props: [
      {prop: "alpha", values: [0, 1]},
      {prop: "y", values: ["+=50", 0]},
      {prop: "duration", values: [0, 0.8]},
    ]
  },
  {
    klass: ".content-to-animate", 
    props: [
      {prop: "alpha", values: [0, 1]},
      {prop: "y", values: ["+=160", 0]},
      {prop: "duration", values: [0, 0.8]},
    ]
  },
  {
    klass: ".image-wrapper__image", 
    props: [
      {prop: "alpha", values: [1, 1]}, 
      {prop: "width", values: [0, "100%"]}, 
      {prop: "left", values: ["-10%", 0]},
      {prop: "duration", values: [0, 1.4]},
    ]
  },
  // {
  //   klass: ".image-wrapper__cover", 
  //   props: [
  //     {prop: "alpha", values: [0, 1]}, 
  //     {prop: "right", values: ["-10%", false]},
  //     {prop: "bottom", values: ["-10%", false]},
  //     {prop: "duration", values: [0, 0.6]},
  //   ]
  // },
];
if (!isMobile()) sectionsToAnimate.forEach((section, i) => {
  if (section.querySelector(".title-to-animate")) gsap.set(section.querySelector(".title-to-animate"), {alpha: 0});
  if (section.querySelector(".image-wrapper__image")) gsap.set(section.querySelector(".image-wrapper__image"), {alpha: 0});
  // if (section.querySelector(".image-wrapper__cover")) gsap.set(section.querySelector(".image-wrapper__cover"), {alpha: 0});
  if (section.querySelector(".content-to-animate")) gsap.set(section.querySelector(".content-to-animate"), {alpha: 0});
  ScrollTrigger.create({
    trigger: section,
    start: "top center-=33%",
    end: "bottom top",
    onEnter:  (self) => {
      toggleSection(self);
    },
    // onEnterBack:  (self) => {
    //   toggleSection(self);
    // },
    // markers: true,
  });
});
function toggleSection(section) {
  // console.log(section.trigger, section.progress.toFixed(1));
  if (section.trigger.dataset.animation == 0) {//section.progress.toFixed(2) < 1 && 
    elementsToAnimate.forEach((animationObject, i) => {
      let elm = section.trigger.querySelector(animationObject.klass),
          props = animationObject.props,
          steps = 4;
      section.trigger.dataset.animation = 1;
      if (elm) {
        let objSet = {}, objTo = {};
        // objTo.duration = 0.6;
        objTo.ease = Power2.easeOut;
        objTo.delay = 0.1 + i/steps;
        // console.log(objTo.style)
        for (let pi = 0; pi < props.length; pi++) {
          let _prop = props[pi].prop,
              _startValue = props[pi].values[0],
              // _endValue = (props[pi].values[1] !== false) ? props[pi].values[1] : objTo.style[_prop];
              _endValue = props[pi].values[1];
          objSet[_prop] = _startValue;
          objTo[_prop] = _endValue;
        }
        // console.log(objSet);
        // console.log(objTo);
        gsap.set(elm, objSet);
        gsap.to(elm, objTo);
      }
    });
  }
}


import Flickity from 'flickity';
// require('flickity-as-nav-for');
require('flickity-imagesloaded');
// var galleries = document.querySelectorAll('.story-gallery');
// galleries.forEach(function(gallery) {
  var carousel = document.querySelector('.f-carousel');
  if (carousel) {
    var flkty = new Flickity( carousel, {
      imagesLoaded: true,
      // percentPosition: false,
      prevNextButtons: false,
      pageDots: false,
      // cellSelector: '.carousel-cell',
      // cellAlign: isMobile() ? 'left' : 'center',
    });
    // var textsCarousel = gallery.querySelector('.f-carousel-texts');
    // var flktyT = new Flickity( textsCarousel, {
    //   pageDots: false,
    //   asNavFor: carousel,
    //   contain: true,
    //   arrowShape: 'M34,85l5-5L13,53.5h87v-7H13L39,20l-5-5L0,50L34,85z',
    //   on: {
    //     change: function(index) {
    //       flkty.selectCell(index);
    //     },
    //     ready: function() {
    //       if (this.slides.length < 2) {
    //         // console.log(this)
    //         this.prevButton.element.classList.add('hide');
    //         this.nextButton.element.classList.add('hide');
    //       }
    //     }
    //   }
    // });
    // var imgs = carousel.querySelectorAll('.carousel-cell img');
    // // get transform property
    // var docStyle = document.documentElement.style;
    // var transformProp = typeof docStyle.transform == 'string' ?
    //   'transform' : 'WebkitTransform';

    // flkty.on( 'scroll', function() {
    //   flkty.slides.forEach( function( slide, i ) {
    //     var img = imgs[i];
    //     var x = ( slide.target + flkty.x ) * -1/3;
    //     img.style[ transformProp ] = 'translateX(' + x  + 'px)';
    //   });
    // });
  }
// });




  
if($("#imap").length) {
  var points = $("#Layer_2>use");
  var tooltips = $('#map_points>li');
  for(var i=0; i<points.length; i++) {
    //console.log(points[i]);
    $(points[i]).css({'cursor': 'pointer'});
    $(points[i]).on('mouseover', function(event){
      var p = event.target;
      if (p.id == '_p6') {
        p.addEventListener('click', openapartaments);
      };
      var curr_tip = $('#map_points>li[data-id="'+p.id+'"]').html();
      $("#imap-tooltip").on('DOMNodeInserted', '.media-card', function(event){
        var targ = $(event.target);
        if(!targ.length) return;
        var l = $('body').hasClass('firefox') ?
                $(p).position().left-Math.round(targ.width() - p.getAttribute('width'))/2 - 44 :
                $(p).position().left-Math.round(targ.width() - p.getAttribute('width'))/2;
        var t = $('body').hasClass('firefox') ?
                Math.round($(p).position().top - targ.height()) - $(".interactive-map").position().top + 44 :
                Math.round($(p).position().top - targ.height()) - $(".interactive-map").position().top;
        //console.log(t);
        var old_scale = parseInt($('body').data('resolution')) < 640 ? 1 : 0.5;
        var mob_l = parseInt($('body').data('resolution')) < 640 ? 0 : l;
        gsap.fromTo(targ, {scale: old_scale, alpha: 0, left: mob_l, top: t+30}, {duration: 1, scale: 1, alpha: 1, left: mob_l, top: t, ease: Power4.easeOut});
      });
      $("#imap-tooltip").html(curr_tip);
    }).on('mouseout', function(event){
      gsap.killTweensOf(event.target);
      $("#imap-tooltip").off('DOMNodeInserted');
      $("#imap-tooltip").html('');
    });
  }
}


/* google map API */
$('#Directions a.button').on('click', function(event){
  changeDirection();
  return false;
});

window.changeDirection = function() {
  var loc = document.getElementById('location').value;
  if (!loc) {
    window.alert('Enter a location!');
    return;
  } else {
    directionsService.route({
      origin: loc,
      destination: edelweiss,
      travelMode: 'DRIVING'
    }, function(response, status) {
      //console.log(loc)
      console.log(response);
      if (status === 'OK') {
        directionsDisplay.setDirections(response);
        directionsDisplay.setMap(map);
      } else {
        window.alert('Directions request failed due to ' + status);
      }
    });
  }
}

function geolocate() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function(position) {
      var geolocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      var circle = new google.maps.Circle({
        center: geolocation,
        radius: position.coords.accuracy
      });
      autocomplete.setBounds(circle.getBounds());
    });
  }
}

function fillInAddress() {
  // Get the place details from the autocomplete object.
  var place = autocomplete.getPlace();
}

window.initMap = function () {
  var bulgaria = 'ChIJifBbyMH-qEAREEy_aRKgAAA', // place Id of Bulgaria
      marker,
      mapOptions,
      markerOptions,
      zoom = $('#main').hasClass('LocationPage') ? 17 : 12,
      geocoder = new google.maps.Geocoder(),
      dom = document.getElementById('gmap');
  if (!dom) return false;
  var locX = parseFloat(dom.getAttribute("data-locx"));
  var locY = parseFloat(dom.getAttribute("data-locy"));
  /*geocoder.geocode({ placeId: bulgaria }, function (results, status) {
    if (status == google.maps.GeocoderStatus.OK) {
      var latitude = results[0].geometry.location.lat();
      var longitude = results[0].geometry.location.lng();
      mapOptions = {
        zoom: 12,
        scrollwheel: false,
        center: new google.maps.LatLng(latitude, longitude),
        //styles: mapStyle,
        mapTypeControl: false,
        zoomControl: true,
        zoomControlOptions: {
            position: google.maps.ControlPosition.LEFT_CENTER
        },
        scaleControl: true,
        streetViewControl: true,
        streetViewControlOptions: {
            position: google.maps.ControlPosition.LEFT_CENTER
        },
        fullscreenControl: false
      }
    } else {
      mapOptions = {
        zoom: 12,
        center: {lat: locX, lng: locY}
      }
    }
    map = new google.maps.Map(dom, mapOptions);
    markerOptions = {
      position: {lat: locX, lng: locY},
      map: map
    };
    marker = new google.maps.Marker(markerOptions);
  });*/
  mapOptions = {
    zoom: zoom,
    center: {lat: locX, lng: locY}
  };
  map = new google.maps.Map(dom, mapOptions);
  markerOptions = {
    position: {lat: locX, lng: locY},
    map: map
  };
  marker = new google.maps.Marker(markerOptions);

  if ($('#main').hasClass('LocationPage') || $('#main').hasClass('ContactsPage')) return;

  directionsService = new google.maps.DirectionsService;
  directionsDisplay = new google.maps.DirectionsRenderer;
  directionsDisplay.setMap(map);

  autocomplete = new google.maps.places.Autocomplete(
    (document.getElementById('location')),
    {types: ['geocode']}
  );

  // When the user selects an address from the dropdown, populate the address
  // fields in the form.
  autocomplete.addListener('place_changed', fillInAddress);
}

function initLoader() {
  var script = document.createElement("script");
  //script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDbXKYhKecjKLL1vrl9CPjpp-mb361cG3o&libraries=places&callback=initMap";
  script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDbXKYhKecjKLL1vrl9CPjpp-mb361cG3o&libraries=places&callback=initMap";
  script.type = "text/javascript";
  script.async = true;
  script.defer = true;
  document.getElementsByTagName("head")[0].appendChild(script);
}
var directionsService,
    directionsDisplay,
    placeSearch,
    autocomplete,
    map,
    componentForm = {locality: 'long_name'},
    helena_blue = "#050543",
    helena_lands = "#1c406d",
    helena = {lat: 42.710441, lng: 27.727078},
    edelweiss = {lat: 42.647667, lng: 23.274}; // Edelweiss id
window.onload = initLoader;
var mapStyle = [
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": helena_blue
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "color": helena_lands
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#121212"
            },
            {
                "lightness": -37
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#121212"
            },
            {
                "lightness": -37
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#406d80"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#406d80"
            }
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#3e606f"
            },
            {
                "weight": 2
            },
            {
                "gamma": 0.84
            }
        ]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
            {
                "weight": 0.6
            },
            {
                "color": "#1a3541"
            }
        ]
    },
    {
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": helena_lands
            }
        ]
    }
];
/* // google map API */
